import React, { Component } from 'react'
import './Home.css'

export default class Home extends Component {
    render() {
        return (
            <div className="Home">
                <div className="lander">
                    <h1>OMOM</h1>
                    <p>Welcome to OMOM!</p>
                </div>
            </div>
        )
    }
}